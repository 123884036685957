import { useState, useContext } from 'react'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Fade from 'react-reveal/Fade'

import { SitesContext } from 'context/SitesContextProvider'

import CollectionsButton from 'components/CollectionsButton'

import s from './ModelMeasurements.module.css'

function ModelMeasurements({
  measurements,
  className,
  listClassName,
  itemClassName,
  valueClassName,
  shoesToggle,
  noToggle,
  lightStyle,
  male,
  fadeDuration = 500,
  units: initialUnits,
  modelProps,
  mobile,
}) {
  const { currentSite } = useContext(SitesContext)
  const router = useRouter()

  const [units, setUnits] = useState(
    initialUnits === 'cm'
      ? 'metric'
      : initialUnits || currentSite?.units || 'imperial'
  )

  if (!measurements || router.query?.boardType === 'talent') return null

  const measurementValue = ({
    fieldName,
    fieldValue,
    fieldValueHuman: { metric, us, uk },
  }) => {
    if (fieldName === 'Shoes' && currentSite?.shoeSizeUnits === 'uk' && uk)
      return uk
    // if (humanValue && fieldName !== 'Shoes') return humanValue
    if (units === 'metric' && metric) return metric
    if (us) return us
    return fieldValue
  }

  const measurementLabel = ({ fieldName }) => {
    if (male && fieldName === 'Bust') return 'Chest'
    return fieldName
  }

  const measurementsToDisplay = measurements.filter(
    ({ fieldName, fieldValue, humanValue }) => {
      if (fieldName === 'Location') return false
      return fieldValue !== null && fieldName !== 'Hair length'
    }
  )

  if (measurementsToDisplay.length === 0) return null

  const setShoeSizeFilter = (shoeSizes) => {
    if (!shoeSizes) return null
    const { us, uk, metric } = shoeSizes?.fieldValueHuman
    return (
      <div className={s.shoeSizesWrapper}>
        <select
          name={mobile ? 'shoeSizesMobile' : 'shoeSizes'}
          id={mobile ? 'shoeSizesMobile' : 'shoeSizes'}
          defaultValue={currentSite?.name === 'London' ? 'uk' : units}
          className={classNames(s.value, valueClassName)}
        >
          {us && <option value="us">US {us}</option>}
          {uk && <option value="uk">UK {uk}</option>}
          {metric && <option value="metric">EU {metric}</option>}
        </select>
      </div>
    )
  }

  const setToggle = (classname = '') => {
    return (
      <>
        <li
          className={classNames(
            s.item,
            classname,
            s.itemMetric,
            itemClassName,
            {
              [s.itemActive]: units === 'metric',
            }
          )}
        >
          <button
            type="button"
            className={s.toggle}
            onClick={() => setUnits('metric')}
          >
            <span className={s.titleValue}>CM</span>
          </button>
        </li>
        <li
          className={classNames(s.item, s.itemImperial, itemClassName, {
            [s.itemActive]: units === 'imperial',
          })}
        >
          <button
            type="button"
            className={s.toggle}
            onClick={() => setUnits('imperial')}
          >
            <span className={s.titleValue}>Inch</span>
          </button>
        </li>
      </>
    )
  }

  return (
    <div className={classNames(className, { [s.lightStyle]: lightStyle })}>
      {!mobile && !noToggle && <CollectionsButton modelProps={modelProps} />}
      <Fade bottom cascade ssrReveal duration={fadeDuration}>
        <div>
          <ul className={classNames(s.list, listClassName)}>
            {!noToggle && !mobile && setToggle()}
            {measurementsToDisplay.map((measurement) => {
              if (!measurement.fieldValue || measurement.fieldValue === '0')
                return null
              return (
                <li
                  key={measurement.fieldName}
                  className={classNames(s.item, itemClassName)}
                >
                  {measurementLabel(measurement)}

                  {measurementValue(measurement) &&
                    (measurement.fieldName === 'Shoes' && shoesToggle ? (
                      setShoeSizeFilter(measurement)
                    ) : (
                      <span className={classNames(s.value, valueClassName)}>
                        {measurementValue(measurement)}
                      </span>
                    ))}
                </li>
              )
            })}
          </ul>
          {mobile && (
            <ul className={s.toggleWrapMobile}>{setToggle(s.toggleMobile)}</ul>
          )}
        </div>
      </Fade>
    </div>
  )
}

ModelMeasurements.propTypes = {
  measurements: PropTypes.array,
  className: PropTypes.string,
  listClassName: PropTypes.string,
  itemClassName: PropTypes.string,
  valueClassName: PropTypes.string,
  noToggle: PropTypes.bool,
  shoesToggle: PropTypes.bool,
  lightStyle: PropTypes.bool,
  male: PropTypes.bool,
  fadeDuration: PropTypes.number,
  units: PropTypes.string,
  mobile: PropTypes.bool,
}
ModelMeasurements.defaultProps = {
  measurements: [],
  className: '',
  listClassName: '',
  itemClassName: '',
  valueClassName: '',
  noToggle: false,
  shoesToggle: false,
  lightStyle: false,
  male: false,
  fadeDuration: 500,
  units: '',
  mobile: false,
}

export default ModelMeasurements
